<template>
  <div class="page-user-noticelist">
    <div class="my-page" :style="flag == 1?'padding-top:20px':''">
      <div class="list-box" :class="flag == 1?'list-box-flag':''">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div class="my-card my-card-item" v-if="dataList.length > 0">
              <van-steps
                direction="vertical"
                :active="active"
                active-color="#47AFA7"
              >
                <van-step v-for="(item, i) in dataList" :key="i">
                  <div class="flow-item" @click="handleClick(item)">
                    <p class="time">{{ item.CreateDate }}</p>
                    <div class="flow-top">
                      <p class="title">{{ item.Title }}</p>
                      <p class="content">{{ item.Content }}</p>
                      <!-- <p class="name">{{ item.CreateUserName }}</p> -->
                      <div class="eye">
                        <!-- <van-icon name="eye-o" size="18" /> -->
                        <p class="read">
                          已读{{ item.readCount }}人/未读{{
                            item.unReadCount
                          }}人
                        </p>
                        <p class="name">{{ item.CreateUserName }}发布</p>
                      </div>
                    </div>
                  </div>
                </van-step>
              </van-steps>
            </div>

            <!-- <div class="my-card" v-for="(item, i) in dataList" :key="i" @click="handleClick(item)">
              <p>公告标题：{{item.Title}}</p>
              <div>发布人：<span>{{item.CreateUserName}}</span></div>
              <div>发布时间：<span>{{item.CreateDate}}</span></div>
            </div> -->
          </van-list>
        </van-pull-refresh>
      </div>
      <!-- <van-button type="primary" block color="#ffe500" @click="add()">添加记录</van-button> -->
    </div>
    <div v-if="userType === '2'" class="add-btn" @click="add">+</div>
  </div>
</template>

<script>
import { Button, List, PullRefresh, Step, Steps, Icon } from "vant";
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Icon.name]: Icon
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      active: 0,
      flag:0
    };
  },
  mounted() {
    if (this.$route.query.flag) {
      this.flag = 1
    }
    //this.getDataList();
  },
  methods: {
    add() {
      this.$router.push({
        path: "NoticeMain"
      });
    },
    getDataList() {
      this.page++;
      this.getList();
    },
    getList() {
      const that = this;
      that.$axios
        .get("/api/Proclamation/ReceiveList", {
          page: that.page,
          limit: that.limit
        })
        .then(res => {
          if (res.code === 200) {
            that.active = res.count;
            console.log(that.active)
            that.refreshing = false;
            that.loading = false;
            that.dataList = that.dataList.concat(res.data);
            if (that.dataList.length >= res.count) {
              that.finished = true;
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 下拉刷新
    onRefresh() {
      this.page = 0;
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = [];
      this.getDataList();
    },
    handleClick(item) {
      this.$router.push({
        path: "NoticeView",
        query: {
          id: item.ID
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
